<template>
    <div>
        <div
            class="contenedor-menu-movil-lugares-instalacion"
            @click="abrirMenu(lugarInstalacion)"
        >
            <i class="mdi mdi-dots-horizontal"></i>
        </div>

        <div class="contenedor-menu-escritorio-lugares-instalacion">
            <MenuEscritorio :lugarInstalacion='lugarInstalacion'/>
        </div>
        <b-modal
            id="modal-menu-lugares-instalacion"
            content-class="modal-menu-modulos-lugares-instalacion-content"
            body-class="modal-menu-modulos-lugares-instalacion-body"
            centered
            title="Center modal"
            title-class="font-18"
            hide-header
            hide-footer
        >
            <MenuMovil :lugarInstalacion='lugarInstalacion'/>
        </b-modal>
    </div>
</template>

<script>
import MenuMovil from "@/components/widgets/menu/MovilWidget";
import MenuEscritorio from "@/components/widgets/menu/DesktopWidget";
export default {
    props:{
        lugarInstalacion:Object
    },
    components: {
        MenuMovil,
        MenuEscritorio,
    },
    mounted(){
        this.$bvModal.hide("modal-menu-lugares-instalacion");
    },
    methods: {
        abrirMenu() {
            this.$bvModal.show("modal-menu-lugares-instalacion");
        },
    },
};
</script>
<style>
.menu-escritorio-lugares-instalacion {
    position: absolute;
    right: 10px;
    margin-top: -10px;
}

.contenedor-menu-movil-lugares-instalacion {
    position: absolute;
    right: 10px;
    display: none;
    cursor: pointer;
    margin-top: -10px;
}
.contenedor-menu-movil-lugares-instalacion i {
    font-size: 1.8rem;
}

@media (max-width: 576px) {
    .contenedor-menu-escritorio-lugares-instalacion {
        display: block;
    }
    .contenedor-menu-movil-lugares-instalacion {
        display: none;
    }
}
</style> 
