<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <GanttComponente />
    </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import GanttComponente from "./gantt-componente.vue";
export default {
   page: {
        title: "Gantt",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
        Layout,
        PageHeader,
        GanttComponente
    },
    data() {
        return {
            title: "Gantt",
            items: [
                {
                    text: "Proyectos",
                    href: `/proyectos/proyectos`,
                },
                {
                    text: "Lugares Instalacion",
                    href: `/proyectos/lugaresInstalacion/${this.$route.params.codigoProyecto}`,
                },
                {
                    text: "Gantt",
                    active: true,
                },
            ],
        };
    },
};
</script>