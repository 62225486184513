<template>
    <ul class="menu-lugar-instalacion">
        <!-- <li>
            Ver Todo
        </li> -->
        <li
            v-if="obtenerPermisos('lugarInstalacion')"
            @click="verLugarInstalacion()"
        >
            Datos Generales
        </li>
        <li
            v-if="obtenerPermisos('informacionTecnica')"
            @click="verInformacionTecnica()"
        >
            Información Técnica
        </li>
        <li v-if="obtenerPermisos('contactos')" @click="verContactos()">
            Contactos
        </li>
        <li
            v-if="obtenerPermisos('solicitudMateriales')"
            @click="verSolicitudMateriales()"
        >
            Gestión de Materiales
        </li>
        <li
            v-if="obtenerPermisos('actividadesProyecto')"
            @click="verActividadesProyecto()"
        >
            Actividades
        </li>
        <li
            v-if="obtenerPermisos('galeriaImagenes')"
            @click="verGaleriaImagenes()"
        >
            Imágenes de inspector
        </li>
        <li v-if="obtenerPermisos('documentos')" @click="verDocumentos()">
            Adjuntos
        </li>
        <li v-if="obtenerPermisos('documentos')" @click="verGantt()">
            Gantt
        </li>
        <li v-if="obtenerPermisos('comentarios')" @click="verComentarios()">
            Comentarios
        </li>
        <li v-if="obtenerPermisos('informeDiario')" @click="verInformeDiario()">
            Informe Diario
        </li>
        <li
            v-if="obtenerPermisos('ubicacionLugarInstalacion')"
            @click="verUbicacion()"
        >
            Ubicación
        </li>
        <li
            v-if="obtenerPermisos('informacionCompleta')"
            @click="verInformacionCompleta()"
        >
            Ver Mas
        </li>
        <li
            v-if="obtenerPermisos('lugaresInstalacion')"
            @click="verLugaresInstalacion()"
        >
            Lugares de Instalación
        </li>
    </ul>
</template>

<script>
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
export default {
    props: {
        lugarInstalacion: Object,
    },
    methods: {
        verLugarInstalacion() {
            this.$router.push({
                name: "lugarInstalacion",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verInformacionTecnica() {
            this.$router.push({
                name: "informacionTecnica",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verUbicacion() {
            this.$router.push({
                name: "ubicacionLugarInstalacion",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verComentarios() {
            this.$router.push({
                name: "comentarios",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verContactos() {
            this.$router.push({
                name: "contactos",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verLugaresInstalacion() {
            this.$router.push({
                name: "lugaresInstalacion",
                params: {
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verActividadesProyecto() {
            this.$router.push({
                name: "actividadesProyecto",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verGaleriaImagenes() {
            this.$router.push({
                name: "galeriaImagenes",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verDocumentos() {
            this.$router.push({
                name: "documentos",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
         verGantt() {
            this.$router.push({
                name: "gantt",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verSolicitudMateriales() {
            this.$router.push({
                name: "solicitudMateriales",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verInformeDiario() {
            this.$router.push({
                name: "informeDiario",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        verInformacionCompleta() {
            this.$router.push({
                name: "informacionCompleta",
                params: {
                    codigoLugarInstalacion: this.lugarInstalacion
                        .codigo_lugar_instalacion,
                    codigoProyecto: this.lugarInstalacion.codigo_proyecto,
                },
            });
        },
        obtenerPermisos(nombreModulo) {
            let permisoModulo = permiso.obtenerPermisos(this.user, nombreModulo);
            if (permisoModulo != null){
                return permisoModulo.lectura
            }
            return permisoModulo;
        },
    },
    computed: {
        ...authUsuarioComputed,
    },
};
</script>
<style>
.modal-menu-modulos-lugares-instalacion-content {
    background-color: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(5px) !important;
    border: 0;
}
.modal-menu-modulos-lugares-instalacion-body {
    padding: 0;
}
.menu-lugar-instalacion {
    padding: 0;
    margin: 0;
}
.menu-lugar-instalacion li {
    text-decoration: none;
    list-style: none;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
}
.menu-lugar-instalacion li:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
    cursor: pointer;
}
</style>
