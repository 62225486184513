<template>
    <v-card>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="card">
                <div class="card-body">
                    <b-alert
                        :show="segundosMensajeActualizacion"
                        dismissible
                        :variant="mensaje.variant"
                        @dismissed="segundosMensajeActualizacion = 0"
                        @dismiss-count-down="
                            actualizarSegundosMensajeActualizacion
                        "
                    >
                        {{ mensaje.texto }}
                    </b-alert>
                    <div class="d-flex">
                        <div class="card-title titulo-tarjetas">Gantt</div>
                
                              <button
                                @click="mostrarModal = true"
                                v-if="rolModuloPermiso.escritura"
                                type="button"
                                class="ms-1 btn btn-success"
                                style="position: absolute;right: 112px;top: 29px;"
                                >
                                <i class="mdi mdi-plus me-1"></i>
                                Crear template
                                </button
                            >
                        
                      
                        <Menu :lugarInstalacion="lugarInstalacion" />
                    </div>
                    <h5 class="card-title-desc mt-30-576 mt-30-1024px">
                        {{ proyectoSeleccionado.nombre_proyecto }} -
                        {{ lugarInstalacion.codigo_proyecto }} -
                        {{ lugarInstalacion.codigo_lugar_instalacion }}
                    </h5>

                      

                    
                    <gantt
                        v-if="renderComponent"
                        class="left-container"
                        v-model="tasks"
                        style="width:100%; height:100%"
                        :readonly="!rolModuloPermiso.escritura"
                        :autoOpenSubTasks="false"
                        :autoZoomToFit="true"
                        :proyectName="projectTitle"
                        :proyectCode="projectCode"
                        @task-updated="logTaskUpdate"
                        @link-updated="logLinkUpdate"
                        @task-selected="selectTask"
                        @onAfterTaskAdd="onAfterTaskAdd"
                        @onAfterTaskDelete="onAfterTaskDelete"
                        @onAfterTaskUpdate="onAfterTaskUpdate"
                        @onAfterLinkAdd="onAfterLinkAdd"
                        @onAfterLinkDelete="onAfterLinkDelete"
                        @onBeforeRowDragEnd="onBeforeRowDragEnd"
                        @onAfterTaskMove="onAfterTaskMove"
                    ></gantt>

                    <b-modal v-model="mostrarModal" title="Seleccionar Template" hide-footer size="lg">

                        <div class="mb-3">
                            <label for="nombreTemplate">Nombre</label>
                            <multiselect
                                v-model="template"
                                :options="templates"
                                placeholder="Seleccionar App Flex Energetica"
                                value="template_id"
                                label="nombre"
                                :searchable="true"
                                class="helo"
                               @input="seleccionarTemplate"
                            ></multiselect>
                        
                        </div>
                            <div class="mb-3">
                                <label for="descripcionTemplate"
                                    >Descripción</label
                                >
                                <div
                                    id="descripcionTemplate"
                                
                                    class="form-control"
                                    style="height: 60px;"

                                > {{descripcionTemplate}}</div>
                                
                            </div>

                            <div class="text-end mt-2 mb-3">
                            <b-button variant="light" @click="cerrarModal"
                                >Cerrar</b-button
                            >
                            <b-button
                                @click="crearTemplateLocal"
                                v-if="rolModuloPermiso.escritura"
                                type="button"
                                variant="primary"
                                class="ms-1"
                                >Crear Gantt</b-button
                            >
                        </div>
                
         
                      </b-modal>
                </div>
            </div>
        </b-overlay>
    </v-card>
</template>

<script>
import Menu from "@/components/widgets/menu/MenuWidget";
// import Menu from "@/components/widgets/menu/menu";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Gantt from "@/components/widgets/gantt/Gantt.vue";
import {
    actividadProyectoMethods,
    actividadProyectoComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { proyectoMethods, proyectoComputed,templateMethods} from "@/state/helpers";
import Multiselect from "vue-multiselect";
import moment from "moment";
import Swal from "sweetalert2";
moment.locale("es");
export default {
    components: { Gantt, Menu,Multiselect },
    data() {
        return {
            gantt: null,
            projectTitle: "",
            projectCode: this.$route.params.codigoProyecto,
            selectedTask: null,
            loading: false,
            mensaje: { variant: "", texto: "" },
            lugarInstalacion: {},
            codigoProyecto: {},
            codigoLugarInstalacion: {},
            segundos: 10,
            segundosMensajeActualizacion: 0,
            tasks: {
                data: [],
                links: [],
            },
            actividadesProyecto: [],
            actividadesProyectoCargando: false,
            renderComponent: true,
            rolModuloPermiso: {},
            proyectoSeleccionado: {},
            mostrarModal:false,
            templates:[],
            template :{nombre:null,descripcion:null},
            descripcionTemplate:null
        };
    },
    async created() {
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };
        await this.obtenerActividadesProyectoLocal();
        this.obtenerTemplatesLocal()
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
        this.forceRerender();
        // setTimeout(() =>{
        //      this.forceRerender();
        // },1000)
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
            this.projectTitle = this.proyectoSeleccionado.nombre_proyecto;
        }
    },
    methods: {
        ...actividadProyectoMethods,
        ...proyectoMethods,
        ...templateMethods,
        cerrarModal(){
            this.mostrarModal = false
            this.template = {nombre:null,descripcion:null}
            this.descripcionTemplate = null
        },
        crearTemplateLocal(){
            const codigo_lugar_instalacion = this.$route.params.codigoLugarInstalacion;
            const codigo_proyecto = this.$route.params.codigoProyecto;

            const codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
            const codigoProyecto = this.$route.params.codigoProyecto;
            const template_id = this.template.template_id

            if (template_id == 'nueva'){
                const mensajeEliminar = Swal.mixin({
                customClass: {
                        title: "font-size-16",
                        confirmButton: "btn btn-primary me-2",
                        cancelButton: "btn btn-light",
                    },
                    buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar toda la Gantt?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Si, Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarGantt({codigoLugarInstalacion,codigoProyecto})
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Gantt elimininada correctamente!!!";

                                        this.mostrarModal = false;
                                        this.$router.go();
                                   
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });

            }else{
                this.crearActividadProyectoTemplate({codigo_lugar_instalacion,codigo_proyecto,template_id})
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Template creado correctamente!!!";
                            this.mostrarModal = false;
                            this.$router.go();
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "El template ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;

                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            }
            
          
        },
        obtenerTemplatesLocal() {
            this.obtenerTemplates(this.template.template_id)
                .then((res) => {
                    let template = {
                        template_id:'nueva',
                        nombre:'Gantt Nueva',
                        descripcion:'Esta opción eliminara toda la Gantt, para luego crear una nueva'
                    }
                    this.templates.unshift(template)
                    res.body.map((item) => {
                        this.templates.push(item);
                    })
                })
                .catch((error) => {});
        },
        seleccionarTemplate(item){
            this.descripcionTemplate = item.descripcion
        },
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                    this.projectTitle = this.proyectoSeleccionado.nombre_proyecto;
                })
                .catch((error) => {});
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        async obtenerActividadesProyectoLocal() {
            this.loading = true;
            this.actividadesProyectoCargando = true;

        await this.obtenerActividadesProyecto({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
            })
                .then((res) => {
                    this.actividadesProyecto = res.body;
                    this.tasks = { data: [], links: [] };
                    let data = [];
                    let dependencias = [];
                    if (this.actividadesProyecto.length > 0) {
                        this.actividadesProyecto.forEach((actividad) => {
                            let personalLista = [];
                            let responsableLista = [];
                            
                            actividad.recursos.forEach((recurso) => {
                                let personal = {
                                    resource_id: "",
                                    key: "",
                                    label: "",
                                    unit: "",
                                    nombre: "",
                                    value: 8,
                                };
                                personal.resource_id =
                                    recurso.rut_personal_obra;
                                personal.key = recurso.rut_personal_obra;
                                personal.label = `${recurso.nombre} ${recurso.apellido_paterno} ${recurso.apellido_materno} - ${recurso.centro_costo_nombre}`;
                                personal.nombre = `${recurso.nombre} ${recurso.apellido_paterno}`;
                                personal.unit = "horas/dias";
                                personalLista.push(personal);
                            });
                            let responsable = {
                                resource_id: "",
                                key: "",
                                label: "",
                                unit: "",
                                nombre: "",
                                value: 8,
                            };
                            if (actividad.rut_responsable != null) {
                                responsable.resource_id =
                                    actividad.rut_responsable;
                                responsable.key = actividad.rut_responsable;
                                responsable.label = `${actividad.nombre_responsable} ${actividad.apellido_paterno_responsable} ${actividad.apellido_materno_responsable}`;
                                responsable.nombre = `${actividad.nombre_responsable} ${actividad.apellido_paterno_responsable}`;
                                // responsable.unit = "horas/dias";
                                responsableLista.push(responsable);
                            } else {
                                responsableLista.push(responsable);
                            }

                            actividad.dependencias.forEach((dependencia) => {
                                  let link = {
                                id: dependencia.actividad_proyecto_dependencia_id,
                                source: dependencia.source,
                                target: dependencia.target,
                                type: dependencia.type,
                            };
                            dependencias.push(link);
                            })

                            let task = {
                                id: actividad.actividad_proyecto_id,
                                text: actividad.actividad,
                                start_date: this.formatDate(
                                    actividad.start_date
                                ),
                                duration: actividad.duration,
                                progress: actividad.progress,
                                actividad_proyecto_id:
                                    actividad.actividad_proyecto_id,
                                depende_de: actividad.depende_de,
                                parent: actividad.parent,
                                total: actividad.total,
                                ponderacion: actividad.ponderacion,
                                unidad: actividad.unidad,
                                cantidadtotal: actividad.cantidad_total,
                                recurso: personalLista,
                                responsable: responsableLista,
                                sort_order:actividad.sort_order,
                                type:actividad.type,
                                index:actividad.sort_order,
                            };
                            data.push(task);

                          
                        });
                        this.tasks.data = data;
                        this.tasks.links = dependencias;
                    }
                    // this.tasks.links = links;
                    // this.forceRerender();
                    this.actividadesProyectoCargando = false;
                    this.loading = false;
                })
                .catch((error) => {
                    this.actividadesProyectoCargando = false;
                    this.loading = false;
                });
        },
        onAfterTaskUpdate(item) {
            this.loading = true;
            let actividadActualizar = {
                codigo_proyecto: this.$route.params.codigoProyecto,
                codigo_lugar_instalacion: this.$route.params.codigoLugarInstalacion,
                actividad_id: "",
                actividad: "",
                depende_de: 0,
                duration: 0,
                start_date: "",
                end_date: "",
                cantidad_total: 0,
                ponderacion: 0,
                unidad: "",
                progress: 0,
                parent: "",
                gantt_id: 0,
                type:'',
                sort_order: 0,
                rut_personal_obra: "",
                nombre_personal_obra: "",
                recurso: [],
            };

            actividadActualizar.actividad_proyecto_id =
                item.actividad_proyecto_id;
            actividadActualizar.start_date = this.formatDate(item.start_date);
            actividadActualizar.end_date = this.formatDate(item.end_date);
            actividadActualizar.duration = item.duration;
            actividadActualizar.progress = item.progress;
            actividadActualizar.parent = item.id;
            actividadActualizar.recurso = item.recurso;
            actividadActualizar.responsable = item.responsable;
            actividadActualizar.actividad = item.text;
            actividadActualizar.unidad = item.unidad;
            actividadActualizar.cantidad_total = item.cantidadtotal;
            actividadActualizar.type = item.parent != '0' ? "gantt.config.types.project" : 'gantt.config.types.task'
            this.actualizarActividadGanttProyecto(actividadActualizar)
                .then((res) => {
                    if (res.body.status_code == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.segundosMensajeActualizacion = this.segundos;
                    }

                    if (res.body.status_code == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Proyecto actualizado, si los cambios realizados no se ven correctamente, recargue la pagina web y compruebe la información";
                             this.segundosMensajeActualizacion = this.segundos;
                        // await this.obtenerActividadesProyectoLocal();
                        // this.forceRerender()
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.loading = false;
                });

        },
        onAfterTaskDelete(actividad) {
            this.loading = true;
            this.eliminarActividadProyecto(actividad.item.actividad_proyecto_id)
                .then((res) => {
                    if (res.body.status_code == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.segundosMensajeActualizacion = this.segundos;
                    }

                    if (res.body.status_code == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Actividad eliminada, si los cambios realizados no se ven correctamente, recargue la pagina web y compruebe la información";
                        this.segundosMensajeActualizacion = this.segundos;
                        // await this.obtenerActividadesProyectoLocal();

                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "No se ha eliminado el registro, intente nuevamente";
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
        },
        onAfterTaskAdd(actividad) {

            let actividadActualizar = {
                codigo_proyecto: "",
                codigo_lugar_instalacion: "",
                actividad_id: "",
                actividad: "",
                depende_de: 0,
                duration: 0,
                start_date: "",
                end_date: "",
                total: 0,
                ponderacion: 0,
                unidad: "",
                progress: 0,
                parent: "",
                gantt_id: 0,
                type:"gantt.config.types.task",
                sort_order: 0,
                rut_personal_obra: "",
                nombre_personal_obra: "",
                recurso: [],
                responsable: [],
            };

            actividadActualizar.codigo_proyecto = this.codigoProyecto;
            actividadActualizar.codigo_lugar_instalacion = this.codigoLugarInstalacion;
            actividadActualizar.start_date = this.formatDate(
                actividad.item.start_date
            );
            actividadActualizar.end_date = this.formatDate(
                actividad.item.end_date
            );
            actividadActualizar.duration = actividad.item.duration;
            actividadActualizar.recurso = actividad.item.recurso;
            actividadActualizar.responsable = actividad.item.responsable;
            actividadActualizar.actividad = actividad.item.text;
            actividadActualizar.unidad = actividad.item.unidad;
            actividadActualizar.cantidad_total = actividad.item.cantidadtotal;
            actividadActualizar.parent = actividad.item.parent;
            actividadActualizar.gantt_id = parseInt(actividad.id);
            actividadActualizar.type = actividad.item.parent != '0' ? "gantt.config.types.project" : 'gantt.config.types.task'
            actividadActualizar.sort_order = parseInt(actividad.index) + 1;
            this.crearActividadProyectoGantt(actividadActualizar)
                .then(async (res) => {
                    if (res.body.status_code == 201) {
                        //  this.$router.go()
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Actividad creada, si los cambios realizados no se ven correctamente, recargue la pagina web y compruebe la información";

                        if (this.guardarCerrar) {
                            this.mostrarModal = false;
                        }
                    }

                    if (res.body.status_code == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "Las actividad ya existe!!!";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    await this.obtenerActividadesProyectoLocal();
                    
                    // this.forceRerender()
                    // this.$router.go()
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
                
        },
        logTaskUpdate(actividad) {},
        logLinkUpdate(actividad) {},
        selectTask(actividad) {},
        onAfterLinkAdd(actividad) {


            let actividadActualizar = {
                actividad_proyecto_dependencia_id: 0,
                codigo_proyecto: "",
                codigo_lugar_instalacion: "",
                actividad_proyecto_id: "",
                source: "",
                target: "",
                type: "",

            };

            actividadActualizar.actividad_proyecto_dependencia_id = parseInt(actividad.item.id);
            actividadActualizar.codigo_proyecto = this.codigoProyecto;
            actividadActualizar.codigo_lugar_instalacion = this.codigoLugarInstalacion;
            actividadActualizar.actividad_proyecto_id = actividad.item.source;
            actividadActualizar.source = actividad.item.source;
            actividadActualizar.target =  actividad.item.target;
            actividadActualizar.type = actividad.item.type;

            this.crearActividadProyectoDependencia(actividadActualizar)
                .then((res) => {
                    if (res.status == 201) {
                        //  this.$router.go()
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Dependencia creada correctamente!!!";

                        if (this.guardarCerrar) {
                            this.mostrarModal = false;
                        } else {
                            // this.resetFormAgregarOtro();
                        }
                    }

                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "La dependencia ya existe ya existe!!!";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    // this.obtenerActividadesProyectoLocal();
                    //  this.$router.go()
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });



        },
        onAfterLinkDelete(actividad) {

            this.loading = true;
            this.eliminarActividadProyectoDependencia(actividad.item.id)
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Dependencia eliminada correctamente!!!";

                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "No se ha eliminado el registro, intente nuevamente";
                    this.loading = false;
                });

        },
        onBeforeRowDragEnd(actividad) {},
        onAfterTaskMove(actividad) {
            
            this.loading = true;
       
            this.actualizarActividadProyectoOrden(actividad)
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Proyecto actualizado correctamente!!!";

                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.loading = false;
                });

        },

        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        formatDate(value) {
            const duration = moment(value).format("DD-MM-yyyy");
            return `${duration}`;
        },
    },
    computed: { ...authUsuarioComputed, ...proyectoComputed },
};
</script>

<style>
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
.container {
    height: 100%;
    width: 100%;
}
.left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
}
</style>
