<template>
  <b-dropdown
    class="menu-escritorio-lugares-instalacion m-2"
    variant="primary"
    menu-class="dropdown-menu-end"
    right
    size="sm"
    text="Ir a Módulo"
  >
    <template v-slot:button-content>
      Ir a Módulo
      <i class="mdi mdi-chevron-down"> </i>
    </template>

    <b-dropdown-item
      v-if="obtenerPermisos('informacionCompleta')"
      href="javascript: void(0);"
      @click="verInformacionCompleta()"
      >Ver Todo</b-dropdown-item
    >

    <!-- <b-dropdown-item
            v-if="obtenerPermisos('lugarInstalacion')"
            href="javascript: void(0);"
            @click="verLugarInstalacion()"
            >Datos Generales</b-dropdown-item
        > -->

    <b-dropdown-item
      v-if="obtenerPermisos('actividadesProyecto')"
      href="javascript: void(0);"
      @click="verActividadesProyecto()"
      >Actividades</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('bitacora')"
      href="javascript: void(0);"
      @click="verBitacora()"
      >Bitacora</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('cubicacion')"
      href="javascript: void(0);"
      @click="
        goToRoute({
          name: 'cubicles',
          params: {
            codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
            codigoProyecto: lugarInstalacion.codigo_proyecto,
          },
          query: {
            sap_project: $route.query.sap_project
              ? $route.query.sap_project
              : undefined,
          },
        })
      "
      >Cubicaciones</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('documentos')"
      href="javascript: void(0);"
      @click="verDocumentos()"
      >Documentos</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('solicitudMateriales')"
      href="javascript: void(0);"
      @click="verSolicitudMateriales()"
      >Gestión de Materiales</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('galeriaImagenes')"
      href="javascript: void(0);"
      @click="verGaleriaImagenes()"
      >Imágenes de inspector</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('custom_link')"
      href="javascript: void(0);"
      @click="
        goToRoute({
          name: 'crm',
          params: {
            codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
            codigoProyecto: lugarInstalacion.codigo_proyecto,
          },
        })
      "
      >Información CRM</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('informeDiario')"
      href="javascript: void(0);"
      @click="verInformeDiario()"
      >Informe Diario</b-dropdown-item
    >
    <b-dropdown-item
      v-if="obtenerPermisos('informacionTecnica')"
      href="javascript: void(0);"
      @click="verInformacionTecnica()"
      >Información Técnica</b-dropdown-item
    >

    <b-dropdown-item
      v-if="obtenerPermisos('custom_link')"
      href="javascript: void(0);"
      @click="
        goToRoute({
          name: 'custom_link',
          params: {
            codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
            codigoProyecto: lugarInstalacion.codigo_proyecto,
          },
        })
      "
      >Links</b-dropdown-item
    >
    <!-- <b-dropdown-item
      v-if="obtenerPermisos('contactos')"
      href="javascript: void(0);"
      @click="verContactos()"
      >Contactos</b-dropdown-item
    > -->
    <b-dropdown-item
      v-if="obtenerPermisos('nested_webpage_installation_point')"
      href="javascript: void(0);"
      @click="
        goToRoute({
          name: 'nested_webpage_installation_point',
          params: {
            codigoLugarInstalacion: lugarInstalacion.codigo_lugar_instalacion,
            codigoProyecto: lugarInstalacion.codigo_proyecto,
          },
        })
      "
      >Smartsheet</b-dropdown-item
    >

    <!-- <b-dropdown-item
      v-if="obtenerPermisos('gantt')"
      href="javascript: void(0);"
      @click="verGantt()"
      >Gantt</b-dropdown-item
    > -->
    <!-- <b-dropdown-item
      v-if="obtenerPermisos('comentarios')"
      href="javascript: void(0);"
      @click="verComentarios()"
      >Comentarios</b-dropdown-item
    > -->
    <!-- <b-dropdown-item
      v-if="obtenerPermisos('ubicacionLugarInstalacion')"
      href="javascript: void(0);"
      @click="verUbicacion()"
      >Ubicación</b-dropdown-item
    >-->
  </b-dropdown>
</template>

<script>
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
export default {
  props: {
    lugarInstalacion: Object,
  },
  data() {
    return {};
  },
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    },
    verLugarInstalacion() {
      this.$router.push({
        name: "lugarInstalacion",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verInformacionTecnica() {
      this.$router.push({
        name: "informacionTecnica",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verUbicacion() {
      this.$router.push({
        name: "ubicacionLugarInstalacion",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verComentarios() {
      this.$router.push({
        name: "comentarios",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verContactos() {
      this.$router.push({
        name: "contactos",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verLugaresInstalacion() {
      this.$router.push({
        name: "lugaresInstalacion",
        params: {
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verActividadesProyecto() {
      this.$router.push({
        name: "actividadesProyecto",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verGaleriaImagenes() {
      this.$router.push({
        name: "galeriaImagenes",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verDocumentos() {
      this.$router.push({
        name: "documentos",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    showCubicles() {
      this.$router.push();
    },
    verGantt() {
      this.$router.push({
        name: "gantt",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verSolicitudMateriales() {
      if (this.$route.query.sap_project) {
        this.$router.push({
          name: "sapRequestMaterials",
          query: {
            sap_project: this.$route.query.sap_project,
          },
        });
      } else {
        this.$router.push({
          name: "solicitudMateriales",
          params: {
            codigoLugarInstalacion:
              this.lugarInstalacion.codigo_lugar_instalacion,
            codigoProyecto: this.lugarInstalacion.codigo_proyecto,
          },
        });
      }
    },
    verInformeDiario() {
      this.$router.push({
        name: "informeDiario",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    verBitacora() {
      this.$router.push({
        name: "bitacora",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },

    verInformacionCompleta() {
      this.$router.push({
        name: "informacionCompleta",
        params: {
          codigoLugarInstalacion:
            this.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: this.lugarInstalacion.codigo_proyecto,
        },
      });
    },
    obtenerPermisos(nombreModulo) {
      let permisoModulo = permiso.obtenerPermisos(this.user, nombreModulo);
      if (permisoModulo != null) {
        return permisoModulo.lectura;
      }
      return permisoModulo;
    },
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>
<style>
.menu-escritorio-lugares-instalacion ul {
  overflow-x: auto;
  height: 240px;
}
.menu-escritorio-lugares-instalacion .dropdown-item {
  font-size: 0.9rem;
}

.menu-escritorio-lugares-instalacion i {
  /* font-size: 1.5rem; */
}

@media (max-width: 576px) {
  .menu-escritorio-lugares-instalacion .dropdown-item {
    font-size: 1rem;
  }

  .menu-escritorio-lugares-instalacion i {
    /* font-size: 2rem; */
  }
}
</style>
