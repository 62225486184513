var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{staticClass:"menu-escritorio-lugares-instalacion m-2",attrs:{"variant":"primary","menu-class":"dropdown-menu-end","right":"","size":"sm","text":"Ir a Módulo"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Ir a Módulo "),_c('i',{staticClass:"mdi mdi-chevron-down"})]},proxy:true}])},[(_vm.obtenerPermisos('informacionCompleta'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verInformacionCompleta()}}},[_vm._v("Ver Todo")]):_vm._e(),(_vm.obtenerPermisos('actividadesProyecto'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verActividadesProyecto()}}},[_vm._v("Actividades")]):_vm._e(),(_vm.obtenerPermisos('bitacora'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verBitacora()}}},[_vm._v("Bitacora")]):_vm._e(),(_vm.obtenerPermisos('cubicacion'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.goToRoute({
        name: 'cubicles',
        params: {
          codigoLugarInstalacion: _vm.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: _vm.lugarInstalacion.codigo_proyecto,
        },
        query: {
          sap_project: _vm.$route.query.sap_project
            ? _vm.$route.query.sap_project
            : undefined,
        },
      })}}},[_vm._v("Cubicaciones")]):_vm._e(),(_vm.obtenerPermisos('documentos'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verDocumentos()}}},[_vm._v("Documentos")]):_vm._e(),(_vm.obtenerPermisos('solicitudMateriales'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verSolicitudMateriales()}}},[_vm._v("Gestión de Materiales")]):_vm._e(),(_vm.obtenerPermisos('galeriaImagenes'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verGaleriaImagenes()}}},[_vm._v("Imágenes de inspector")]):_vm._e(),(_vm.obtenerPermisos('custom_link'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.goToRoute({
        name: 'crm',
        params: {
          codigoLugarInstalacion: _vm.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: _vm.lugarInstalacion.codigo_proyecto,
        },
      })}}},[_vm._v("Información CRM")]):_vm._e(),(_vm.obtenerPermisos('informeDiario'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verInformeDiario()}}},[_vm._v("Informe Diario")]):_vm._e(),(_vm.obtenerPermisos('informacionTecnica'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.verInformacionTecnica()}}},[_vm._v("Información Técnica")]):_vm._e(),(_vm.obtenerPermisos('custom_link'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.goToRoute({
        name: 'custom_link',
        params: {
          codigoLugarInstalacion: _vm.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: _vm.lugarInstalacion.codigo_proyecto,
        },
      })}}},[_vm._v("Links")]):_vm._e(),(_vm.obtenerPermisos('nested_webpage_installation_point'))?_c('b-dropdown-item',{attrs:{"href":"javascript: void(0);"},on:{"click":function($event){return _vm.goToRoute({
        name: 'nested_webpage_installation_point',
        params: {
          codigoLugarInstalacion: _vm.lugarInstalacion.codigo_lugar_instalacion,
          codigoProyecto: _vm.lugarInstalacion.codigo_proyecto,
        },
      })}}},[_vm._v("Smartsheet")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }