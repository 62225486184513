<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<template>
  <!-- start page title -->
  <div class="row mt-3">
    <div class="col-12">
      <div
        class="page-title-box d-block d-md-flex align-items-center justify-content-between titulo-pagina"
      >
        <h4 class="my-2 font-size-18">{{ title }}</h4>

        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<style>
.btn.btn-primary {
  color: #fff !important;
}
@media (max-width: 600px) {
  .titulo-pagina h4 {
    font-size: 0.8rem !important;
  }
  .breadcrumb-item {
    font-size: 0.7rem;
  }
}
</style>
