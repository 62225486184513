<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import {
    proyectoComputed,
    comentarioComputed,
    informacionTecnicaComputed,
    lugarInstalacionComputed,
    contactoComputed,
    tipoProyectoComputed,
    tipoVentaComputed,
    etapaProyectoComputed,
    subEtapaProyectoComputed,
    localizacionComputed,
    tipoInstalacionComputed,
    tipoClienteComputed,
    normativaComputed,
    appFlexEnergeticaComputed,
    actividadComputed,
    actividadTipoProyectoConfiguracionComputed,
    actividadProyectoComputed,
    galeriaImagenesComputed,
    usuarioComputed,
    cargoComputed,
    documentoComputed,
    tipoDocumentoComputed,
    nombreDocumentoComputed,
    productoComputed,
    solicitudMaterialComputed,
    rolComputed,
    moduloPermisoComputed,
    rolModuloPermisoComputed,
    usuarioBodegaComputed,
    bodegaComputed,
    actividadProyectoAvanceDiarioComputed,
    actividadProyectoPersonalObraHorasTrabajadasComputed,
    personalComputed,
    unidadComputed,
    templateComputed

} from "@/state/helpers";

export default {
    components: {
        Vertical,
        Horizontal,
    },
    data() {
        return {
            mostrarMensajeInternet: false,
            mostrarMensajeTimeOut: false,
        };
    },
    computed: {
        ...layoutComputed,
        ...authUsuarioComputed,
        ...proyectoComputed,
        ...comentarioComputed,
        ...informacionTecnicaComputed,
        ...lugarInstalacionComputed,
        ...contactoComputed,
        ...tipoProyectoComputed,
        ...tipoVentaComputed,
        ...etapaProyectoComputed,
        ...subEtapaProyectoComputed,
        ...localizacionComputed,
        ...tipoInstalacionComputed,
        ...tipoClienteComputed,
        ...normativaComputed,
        ...appFlexEnergeticaComputed,
        ...actividadComputed,
        ...actividadTipoProyectoConfiguracionComputed,
        ...actividadProyectoComputed,
        ...galeriaImagenesComputed,
        ...usuarioComputed,
        ...cargoComputed,
        ...documentoComputed,
        ...tipoDocumentoComputed,
        ...nombreDocumentoComputed,
        ...productoComputed,
        ...solicitudMaterialComputed,
        ...rolComputed,
        ...moduloPermisoComputed,
        ...rolModuloPermisoComputed,
        ...usuarioBodegaComputed,
        ...bodegaComputed,
        ...actividadProyectoAvanceDiarioComputed,
        ...actividadProyectoPersonalObraHorasTrabajadasComputed,
        ...personalComputed,
        ...unidadComputed,
        ...templateComputed
    },

    mounted() {
        setInterval(() => {
            this.comprobarInternet();
        }, 5000);

        if (this.user == null) {
            this.$router.push({
                name: "login",
            });
        }
    },
    methods: {
        comprobarInternet() {
            if (window.navigator.onLine) {
                this.mostrarMensajeInternet = false;
            } else {
                this.mostrarMensajeInternet = true;
            }
        },
    },
    watch: {
        errorProyecto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorTipoProyecto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorComentario(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorInformacionTecnica(newCount, oldCount) {
            this.mostrarMensajeInternet = true;
        },
        errorLugarInstalacion(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorContacto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorTipoProyecto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorTipoVenta(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorEtapaProyecto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorSubEtapaProyecto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorLocalizacion(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorTipoInstalacion(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorTipoCliente(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorNormativa(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorAppFlexEnergetica(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorActividad(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorActividadTipoProyectoConfiguracion(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorActividadProyecto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorGaleriaImagenes(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorUsuario(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorCargo(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorDocumento(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorTipoDocumento(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorNombreDocumento(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorProducto(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorSolicitudMaterial(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorRol(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorModuloPermiso(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorRolModuloPermiso(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorUsuarioBodega(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorBodega(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorActividadProyectoAvanceDiario(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorActividadProyectoPersonalObraHorasTrabajadas(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorPersonal(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorUnidad(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
        errorTemplate(newCount, oldCount) {
            if (newCount == "timeout") {
                this.mostrarMensajeTimeOut = true;
            }
            if (newCount == "sininternet") {
                this.mostrarMensajeInternet = true;
            }
        },
    },
};
</script>

<template>
    <div>
        <div
            class="alert alert-danger"
            role="alert"
            style="z-index: 99999;font-size: 14px;position: fixed;width: 100%;"
            v-if="mostrarMensajeTimeOut"
        >
            <h5 class="alert-heading">
                <i class="mdi mdi-timer-off me-2"></i> Tiempo de espera
                agotado
            </h5>
            <hr />
            <p>
                Se ha agotado el tiempo de espera con la respuesta del servidor,
                esto puede ser por la lentitud de la conexión a internet, es
                probable, que la solicitud fue recibida correctamente por el
                servidor, pero, por problemas de lentitud, no hemos recibido su
                respuesta, por ello, cuando tenga mejor conexión, recargue la
                página he intente nuevamente.
            </p>
            <hr />
            <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                @click="mostrarMensajeTimeOut = false"
            >
                Cerrar
            </button>
        </div>

        <div
            class="alert alert-danger"
            role="alert"
            style="z-index: 99999;font-size: 14px;position: fixed;width: 100%;"
            v-if="mostrarMensajeInternet"
        >
            <h5 class="alert-heading">
                <i class="mdi mdi-signal-off me-2"></i> Sin conexión a
                internet
            </h5>
            <hr />
            <p>
                No se ha detectado conexión a internet, intente más tarde.
            </p>
            <hr />
            <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                @click="mostrarMensajeInternet = false"
            >
                Cerrar
            </button>
        </div>

        <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
            <slot />
        </Vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot />
        </Horizontal>
    </div>
</template>

<style>
.modal-header .close,
.alert .close {
    height: 1.3em !important;
}
</style>
